import React from "react";
import ReactDOM from 'react-dom';
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
        main: '#C72039',
        contrastText: '#fff',
      },
    background: {
      default: "#F9FAFB"
    }
  },
  typography:{
      "fontFamily": `'PT Mono', monospace`,
      "fontSize": 14,
      "fontWeightLight": 300,
      "fontWeightRegular": 400,
      "fontWeightMedium": 500
  }
});


