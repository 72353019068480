import { ethers } from "ethers";
import abi from "./abi/PhAzuki.json";


let provider, signer;

const ABI = abi;
const CONTRACT_ADDR = process.env.REACT_APP_CONTRACT_ADDR;

if (typeof window.ethereum !== "undefined") {
  provider = new ethers.providers.Web3Provider(window.ethereum);
  signer = provider.getSigner();
}

const Contract = (inject) => {
    return new ethers.Contract(CONTRACT_ADDR, ABI.abi, inject);
}

const isMintingPaused = async () => {
    return Contract(provider).mintPaused();
}

const getMintStatus = async () => {
    const saleTime = parseInt(await Contract(provider).saleTime());
    const salePrice = ethers.utils.formatEther(await Contract(provider).mintPrice());
    const totalSupply = parseInt(await Contract(provider).totalSupply());
    return { time: saleTime, price: salePrice, supply: totalSupply };
}

const mint = async (address, amount, price) => {
    const overrides = {
        value: ethers.utils.parseEther((price * amount).toString())
    }
    const tx = await Contract(signer).publicMint(amount, address, overrides);
    const receipt = await tx.wait();
    return receipt;
}

export {
    isMintingPaused,
    getMintStatus,
    mint
}