import React, { useState, useEffect } from "react";
import { Container, Typography, Stack, Button, Divider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import TwitterIcon from "@mui/icons-material/Twitter";
import { theme } from "./theme";
import LOGO from "./asset/logo.png";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// ethers
import { ethers } from "ethers";
import { isMintingPaused, getMintStatus, mint } from "./web3Service";

function App() {
  const [mintAmount, setMintAmount] = useState(1);
  const [walletAddr, setWalletAddr] = useState("");
  const [numMinted, setNumMinted] = useState(2969);

  function increaseNumber() {
    if (mintAmount < 100) {
      setMintAmount(mintAmount + 1);
    }
  }

  function decreaseNumber() {
    if (mintAmount > 1) {
      setMintAmount(mintAmount - 1);
    }
  }

  const onMint = async () => {
    try {
      const receipt = await mint(walletAddr, mintAmount, 0.02);
      console.log(receipt);
      toast.success(`Successfully Minted ${mintAmount} PhAzuki`);
      await getStatus();
    } catch (e) {
      toast.error(e.message);
    }
  };

  const getStatus = async () => {
    const status = await getMintStatus();
    setNumMinted(status.supply);
  };

  const connectWallet = async () => {
    try {
      if (typeof window.ethereum !== "undefined") {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const [account] = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const { chainId } = await provider.getNetwork();
        if (chainId === 1) {
          setWalletAddr(account);
        } else {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x1" }],
          });
          throw new Error("Wrong Network.");
        }
        return account;
      } else {
        throw new Error("No Wallet Connected");
      }
    } catch (e) {
      console.log(e);
      toast.error(e.message, { theme: "colored" });
    }
  };

  useEffect(() => {
    getStatus();
    connectWallet();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer />
      <CssBaseline />
      <Container maxWidth="lg">
        <Stack justifyContent="center" height="100vh" spacing={5}>
          <img src={LOGO} width="350px" />
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Born in Metaverse. Building for the UnRugged. <br />
            Creators of{" "}
            <span style={{ textDecoration: "line-through" }}>PhAzuki</span>.
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            <span style={{ textDecoration: "underline" }}>
              Goodbye@notchirulabs.com
            </span>{" "}
            <span style={{ color: "#646465" }}>(not a real email)</span>
          </Typography>
          <Typography>
            Our lawyers told us to put something here to remind you that we are,
            in fact, definitely not Chiru Labs.
          </Typography>
          <Stack sx={{ pt: 10 }} spacing={3}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Get{" "}
              <span style={{ textDecoration: "line-through" }}>PhAzuki</span>,
              Goodbye Ruggabond.
            </Typography>

            {/* <Typography
              variant="h6"
              sx={{ fontWeight: "bold" }}
              color="textSecondary"
            >
              Pop-up Mint, Notification On.
            </Typography> */}
            <>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold" }}
                color="textSecondary"
              >
                {2969 - numMinted}/2969
              </Typography>
              <Typography sx={{ fontWeight: "bold" }} color="textSecondary">
                Ξ{0.02 * mintAmount}
              </Typography>
              <Stack spacing={2} direction="row">
                <Typography
                  variant="h5"
                  color="primary"
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={decreaseNumber}
                >
                  -
                </Typography>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {mintAmount}
                </Typography>
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{ cursor: "pointer", fontWeight: "bold" }}
                  onClick={increaseNumber}
                >
                  +
                </Typography>
                <Stack direction="row" sx={{ pl: 5 }} spacing={2}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={() => setMintAmount(3)}
                  >
                    3
                  </Typography>
                  <Divider orientation="vertical" />
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={() => setMintAmount(5)}
                  >
                    5
                  </Typography>
                  <Divider orientation="vertical" />
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={() => setMintAmount(10)}
                  >
                    10
                  </Typography>
                  <Divider orientation="vertical" />
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", cursor: "pointer" }}
                    onClick={() => setMintAmount(20)}
                  >
                    20
                  </Typography>
                </Stack>
              </Stack>
              <Button
                variant="contained"
                color="primary"
                sx={{ width: "200px", color: "#fff" }}
                disableRipple
                disableFocusRipple
                onClick={onMint}
                disabled={!walletAddr}
              >
                <Typography sx={{ fontWeight: "bold" }}>MINT</Typography>
              </Button>
                <Typography
              sx={{ fontWeight: "bold" }}
              color="textSecondary"
            >
              Share with My Friend
            </Typography>
            </>
          </Stack>
          <Typography variant="contained">
            <TwitterIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                window.open("https://twitter.com/PhazukiV2", "_blank")
              }
            />
          </Typography>
        </Stack>
      </Container>
    </ThemeProvider>
  );
}

export default App;
